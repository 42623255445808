
import { Contract } from '@ethersproject/contracts'
import { Addresses } from './Addresses'
import { Interfaces } from './Interfaces'



export const Contracts = {
  NSM: new Contract(Addresses.NSM, Interfaces.NSM),
  FLV: new Contract(Addresses.FLV, Interfaces.FLV),
  WBNB: new Contract(Addresses.WBNB, Interfaces.WBNB),
  WETH: new Contract(Addresses.WETH, Interfaces.WETH),
  
  PresaleFLV: new Contract(Addresses.PresaleFLV, Interfaces.PresaleFLV),

  nsm: {
    Pair: new Contract(Addresses.nsm.Pair, Interfaces.nsm.Pair),
    Migrate: new Contract(Addresses.nsm.Migrate, Interfaces.nsm.Migrate),
  },
  
  NotTools: {
    LPDataV3: new Contract(Addresses.NotTools.LPDataV3, Interfaces.NotTools.LPDataV3),
    ERC20Data: new Contract(Addresses.NotTools.ERC20Data, Interfaces.NotTools.ERC20Data),
    MultiSend: new Contract(Addresses.NotTools.MultiSend, Interfaces.NotTools.MultiSend),
    BalancesV2: new Contract(Addresses.NotTools.BalancesV2, Interfaces.NotTools.BalancesV2),
    LivePriceV3: new Contract(Addresses.NotTools.LivePriceV3, Interfaces.NotTools.LivePriceV3),
    FactoryCreate2: new Contract(Addresses.NotTools.FactoryCreate2, Interfaces.NotTools.FactoryCreate2),
  },

  Pancake: {
    Router: new Contract(Addresses.Pancake.Router, Interfaces.Pancake.Router),
    Factory: new Contract(Addresses.Pancake.Factory, Interfaces.Pancake.Factory),
  },

}

