import React from 'react';

import {Container, ContentBlock, ContentRow, MainContent, Section, SectionRow} from '../components/base/base';
import {BigLabel} from '../typography/BigLabel';
import {TextInline} from '../typography/Text';
import {Title} from '../typography/Title';
import { Contribute } from './Contribute';
import { Instructions } from './Instructions';
import { Stats2 } from './Stats2';
import { Stats } from './Stats';
import { Claim } from './Claim';
import { Stats3 } from './Stats3';
import { BlockInfo } from './BlockInfo';
import { MyStats } from './MyStats';
import { FlavorsAddresses } from './FlavorsAddresses';
import { MyContributions } from './MyContributions';

export function Presale() {
	return (
		<MainContent>
			<Container>

        <Instructions></Instructions>
        
        <MyStats></MyStats>
        <MyContributions></MyContributions>
        <Contribute></Contribute>
        <Claim></Claim>
        <FlavorsAddresses></FlavorsAddresses>
        <BlockInfo></BlockInfo>
			</Container>
		</MainContent>
	);
}
