import React from 'react';
import {formatEther} from '@ethersproject/units';

import {useEtherBalance, useEthers, useBlockNumber, useBlockMeta, useTokenBalance} from '@usedapp/core';
import {Container, ContentBlock, ContentRow, MainContent, Section, SectionRow} from '../components/base/base';
import {Label} from '../typography/Label';
import {Title} from '../typography/Title';
import {formatUnits} from '@ethersproject/units';
import { BigLabel } from '../typography/BigLabel';

import { Addresses } from '../components/contracts/Addresses';
import { TextInlineConsolas } from '../typography/Text';

//import { IceCreamMan } from '../components/getData/IceCreamMan';
import { GetInfo, GetAddresses } from '../hooks/GetInfo';
//import { GetMyInfo } from '../hooks/GetMyInfo';
import { helpers } from '../components/helpers/helpers';
import { Stats2 } from './Stats2';
//import { PresaleContractAddress } from '../components/getData/PresaleContractAddress';
import { TimeUntilContributionsEnabled } from '../hooks/GetInfo';


export function FlavorsAddresses() {
	const blockNumber = useBlockNumber();
	const {chainId} = useEthers();
	const {timestamp} = useBlockMeta();

	const {account} = useEthers();
	const userBalance = useEtherBalance(account);
  const userBalanceFLV = useTokenBalance(Addresses.FLV, account);
	//const iceCreamMan = GetIceCreamMan();
  //const presaleContractAddress = PresaleContractAddress();
	const {globalTotal_maxContribution_, globalTotal_contributions_, globalTotal_claims_, flvPerNativeCoin_, claimsEnabled_, contributionsEnabled_} = GetInfo();
  const {presaleFLV, flv, creamery, iceCreamMan_, pendingICM_} = GetAddresses();
  //const {remainingBNBcontribution,  holdersClaimableFLV,  holdersCurrentMaxSell,  holderContributions_,  claimedFLV_,  completedContributions_,  completedClaims_} = GetMyInfo();

    return (
				<Section>
						<ContentBlock>
								<ContentRow>
              <BigLabel>Flavors Addresses</BigLabel>
              </ContentRow>
							{presaleFLV && (
								<ContentRow>
									<Label>Flavors Token: </Label> <TextInlineConsolas>To Be Announced{/*{flv} */}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
							{presaleFLV && (
								<ContentRow>
									<Label>Presale Contract: </Label> <TextInlineConsolas>{presaleFLV}</TextInlineConsolas>{' '}
								</ContentRow>
							)}{/*
							{iceCreamMan_ && (
								<ContentRow>
									<Label>Ice Cream Man: </Label> <TextInlineConsolas>{iceCreamMan_}</TextInlineConsolas>{' '}
								</ContentRow>
							)}*/}{/*
							{creamery && (
								<ContentRow>
									<Label>Creamery: </Label> <TextInlineConsolas>{creamery}</TextInlineConsolas>{' '}
								</ContentRow>
							)}*/}
						</ContentBlock>
				</Section>
	);
}