import React from 'react';
import {formatEther} from '@ethersproject/units';

import {useEtherBalance, useEthers, useBlockNumber, useBlockMeta, useTokenBalance} from '@usedapp/core';
import {Container, ContentBlock, ContentRow, MainContent, Section, SectionRow} from '../components/base/base';
import {Label} from '../typography/Label';
import {Title} from '../typography/Title';
import {formatUnits} from '@ethersproject/units';
import { BigLabel } from '../typography/BigLabel';

import { Addresses } from '../components/contracts/Addresses';
import { TextInlineConsolas } from '../typography/Text';

//import { IceCreamMan } from '../components/getData/IceCreamMan';
import { GetInfo, GetAddresses } from '../hooks/GetInfo';
//import { GetMyInfo } from '../hooks/GetMyInfo';
import { helpers } from '../components/helpers/helpers';
import { Stats2 } from './Stats2';
//import { PresaleContractAddress } from '../components/getData/PresaleContractAddress';
import { TimestampContributionsDisabled } from '../hooks/GetInfo';


export function MyStats() {
	const blockNumber = useBlockNumber();
	const {chainId} = useEthers();
	const {timestamp} = useBlockMeta();

	const {account} = useEthers();
	const userBalance = useEtherBalance(account);
  const userBalanceFLV = useTokenBalance(Addresses.FLV, account);
	//const iceCreamMan = GetIceCreamMan();
  //const presaleContractAddress = PresaleContractAddress();
	const {globalTotal_maxContribution_, globalTotal_contributions_, globalTotal_claims_, flvPerNativeCoin_, claimsEnabled_, contributionsEnabled_} = GetInfo();
  const {presaleFLV, flv, creamery, iceCreamMan_, pendingICM_} = GetAddresses();
  //const {remainingBNBcontribution,  holdersClaimableFLV,  holdersCurrentMaxSell,  holderContributions_,  claimedFLV_,  completedContributions_,  completedClaims_} = GetMyInfo();
  const timestampContributionsDisabled = TimestampContributionsDisabled()
    return (      
				<Section>
          <SectionRow>
					<ContentBlock>
            {account && (
              <ContentRow>
                <Label>Account:</Label> <TextInlineConsolas>{account}</TextInlineConsolas>
              </ContentRow>
            )}
            {userBalance && (
              <ContentRow>
                <Label>BNB Balance:</Label> <TextInlineConsolas>{Number(formatEther(userBalance)).toFixed(6)}</TextInlineConsolas> <Label>BNB</Label>
              </ContentRow>
            )}
						{userBalanceFLV && (
							<ContentRow>
								<Label>Flavors Balance</Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(formatUnits(userBalanceFLV, 9)))}</TextInlineConsolas> <Label>NSM</Label>
							</ContentRow>
            )}
					</ContentBlock>
          </SectionRow>
						<ContentBlock>
              <ContentRow>
                <BigLabel>Total Contributions</BigLabel>
              </ContentRow>
							{/*{globalTotal_maxContribution_ && (
								<ContentRow>
									<Label> Max Contribution per Wallet: </Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(globalTotal_maxContribution_._hex/(10**18)))} BNB</TextInlineConsolas>{' '}
								</ContentRow>
							)}*/}
              
							{globalTotal_contributions_ && (
								<ContentRow>
									<Label>Total Presale Contributions: </Label> <TextInlineConsolas>{Number(globalTotal_contributions_._hex/(10**18))} BNB</TextInlineConsolas>{' '}
								</ContentRow>
							)}
							{globalTotal_claims_ && (
								<ContentRow>
									<Label>Total Presale FLV Claimed: </Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(globalTotal_claims_._hex/(10**9)))} FLV</TextInlineConsolas>{' '}
								</ContentRow>
							)}
							{flvPerNativeCoin_ && (
								<ContentRow>
									<Label>Rate: </Label><TextInlineConsolas>{helpers.numberWithCommas(Number(flvPerNativeCoin_._hex/(10**9)))} FLV per BNB</TextInlineConsolas>{' '}
								</ContentRow>
              )}


              
	              <ContentRow>
									<Label>Contributions Enabled:</Label> <TextInlineConsolas>{'No'}</TextInlineConsolas>{' '}
								</ContentRow>

              { /*
							{contributionsEnabled_ && (                
								<ContentRow>
									<Label>Contributions Enabled:</Label> <TextInlineConsolas>{contributionsEnabled_?'Yes':'No'}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
							{!contributionsEnabled_ && (                
								<ContentRow>
									<Label>Contributions Enabled:</Label> <TextInlineConsolas>{contributionsEnabled_?'Yes':'No'}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
              
              */}
              
              {claimsEnabled_ && (
								<ContentRow>
									<Label>Claims Enabled:</Label> <TextInlineConsolas>{claimsEnabled_?'Yes':'No'}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
              {!claimsEnabled_ && (
								<ContentRow>
									<Label>Claims Enabled:</Label> <TextInlineConsolas>{claimsEnabled_?'Yes':'No'}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
                
  
              {timestampContributionsDisabled && (
                <ContentRow>
                  <Label>Presale Ends in </Label> <TextInlineConsolas>{
                      Math.floor((((Number(timestampContributionsDisabled)*1000)-(new Date()).getTime())/1000)/60/60) 
                      + ' Hours, '
                      +  Math.floor((((((Number(timestampContributionsDisabled)*1000)-(new Date()).getTime())/1000)/60/60) % 1)*60)
                  } Minutes</TextInlineConsolas>{' '}
                </ContentRow>
              )}

              
						</ContentBlock>
				</Section>
	);
}