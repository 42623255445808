import React from 'react';
import {formatEther} from '@ethersproject/units';

import {useEtherBalance, useEthers, useBlockNumber, useBlockMeta, useTokenBalance} from '@usedapp/core';
import {Container, ContentBlock, ContentRow, MainContent, Section, SectionRow} from '../components/base/base';
import {Label} from '../typography/Label';
import {Title} from '../typography/Title';
import {formatUnits} from '@ethersproject/units';
import { BigLabel } from '../typography/BigLabel';

import { Addresses } from '../components/contracts/Addresses';
import { TextInlineConsolas } from '../typography/Text';

//import { IceCreamMan } from '../components/getData/IceCreamMan';
import { GetInfo, GetAddresses } from '../hooks/GetInfo';
//import { GetMyInfo } from '../hooks/GetMyInfo';
import { helpers } from '../components/helpers/helpers';
import { Stats2 } from './Stats2';
//import { PresaleContractAddress } from '../components/getData/PresaleContractAddress';


export function BlockInfo() {
	const blockNumber = useBlockNumber();
	const {chainId} = useEthers();
	const {timestamp} = useBlockMeta();

	const {account} = useEthers();
	const userBalance = useEtherBalance(account);
  const userBalanceFLV = useTokenBalance(Addresses.FLV, account);
	//const iceCreamMan = GetIceCreamMan();
  //const presaleContractAddress = PresaleContractAddress();
  
    return (
      <Section>
					<ContentBlock>
          <ContentRow>
            <Label>Chain id:</Label> <TextInlineConsolas>{chainId}</TextInlineConsolas>
          </ContentRow>
          <ContentRow>
            <Label>Current block:</Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(blockNumber))}</TextInlineConsolas>
          </ContentRow>
          {timestamp && (
            <ContentRow>
              <Label>Current block timestamp:</Label> <TextInlineConsolas>{timestamp.toLocaleString()}</TextInlineConsolas>
            </ContentRow>
          )}
        </ContentBlock>
        </Section>
	);
}