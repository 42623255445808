
import { Container, MainContent, Section, SectionRow } from '../components/base/base'
import { TransactionClaimFLV } from '../components/Transactions/PresaleTx'
import { Title } from '../typography/Title'
import styled from 'styled-components'
import React from 'react'


export function Claim() {

  return (
        <Section>
            <TransactionClaimFLV />
        </Section>
  )
}

const TableGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`
