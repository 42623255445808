import React from 'react';
import styled from 'styled-components';

import {useEthers, useEtherBalance} from '@usedapp/core';
import {Container, ContentBlock, ContentRow, MainContent, Section, SectionRow} from '../components/base/base';
import {Label} from '../typography/Label';
import {Title} from '../typography/Title';
import {formatUnits} from '@ethersproject/units';
import {TextInlineConsolas} from '../typography/Text';
import {Addresses} from '../components/contracts/Addresses';
import {helpers} from '../components/helpers/helpers';
import {BigLabel} from '../typography/BigLabel';
import {TransactionContributeBNB} from '../components/Transactions/PresaleTx';
import {ContributeToPresale} from '../components/Transactions/ContributeToPresale';
import {ContributeMAXToPresale} from '../components/Transactions/ContributeToPresale';
import {Stats3} from './Stats3';

export function Contribute() {
	const {account} = useEthers();
	const userBalanceBNB = useEtherBalance(account);

	return (
				<Section>
						{ /* <ContributeToPresale></ContributeToPresale> */ }
				</Section>
	);
}

const TableGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;
`;
