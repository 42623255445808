// hooks/index.ts
import { ethers } from "ethers";
import { useContractCall } from "@usedapp/core";
import { Interfaces } from "../components/contracts/Interfaces";
import { Addresses } from "../components/contracts/Addresses";

const contractInterface = Interfaces.PresaleFLV;
const address = Addresses.PresaleFLV;

//export function GetPresaleContractAddress() {
//  const presaleContractAddress: any = useContractCall({
//    abi: contractInterface,
//    address: address,
//    method: "presaleContractAddress",
//    args: [],
//  }) ?? [];
//  return presaleContractAddress;
//  }

//
//  export function GetCreamery() {
//    const creamery: any = useContractCall({
//      abi: contractInterface,
//      address: address,
//      method: "Creamery",
//      args: [],
//    }) ?? [];
//    return creamery;
//  }
  

//
//export function GetIceCreamMan() {
//  const iceCreamMan: any = useContractCall({
//    abi: contractInterface,
//    address: address,
//    method: "iceCreamMan",
//    args: [],
//  }) ?? [];
//  return iceCreamMan;
//}

export function CanISell() {
  const canISell: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: "canISell",
    args: [],
  }) ?? [];
  return canISell;
}
export function GetContractNativeCoinBalance() {
  const contractNativeCoinBalance: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: "contractNativeCoinBalance",
    args: [],
  }) ?? [];
  return contractNativeCoinBalance;
}


export function GetInfo() {
  const [
    claimsEnabledOnBlockNumber_,
    dayNumber_,
    globalTotal_maxContribution_,
    globalTotal_contributions_,
    globalTotal_claims_,
    flvPerNativeCoin_,
    claimsEnabled_,
    contributionsEnabled_

  ]: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: "getInfo",
    args: [],
  }) ?? [];
  return {
    claimsEnabledOnBlockNumber_,
    dayNumber_,
    globalTotal_maxContribution_,
    globalTotal_contributions_,
    globalTotal_claims_,
    flvPerNativeCoin_,
    claimsEnabled_,
    contributionsEnabled_
  };
}

export function GetAddresses() {
  const [
    presaleFLV,
    flv,
    creamery,
    iceCreamMan_,
    pendingICM_
  ]: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: "getAddresses",
    args: [],
  }) ?? [];
  return {
    presaleFLV,
    flv,
    creamery,
    iceCreamMan_,
    pendingICM_
  };
}


export function GetMyInfo() {
  const [
    remainingBNBcontribution,
    holdersClaimableFLV,
    holdersCurrentMaxSell,
    holderContributions_,
    claimedFLV_,
    completedContributions_,
    completedClaims_
  ]: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: "getMyInfo",
    args: [],
  }) ?? [];
  return {remainingBNBcontribution,
    holdersClaimableFLV,
    holdersCurrentMaxSell,
    holderContributions_,
    claimedFLV_,
    completedContributions_,
    completedClaims_
  }
}
export function TimeUntilContributionsEnabled() {
  const timeUntilContributionsEnabled: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: "timeUntilContributionsEnabled",
    args: [],
  }) ?? [];
  return timeUntilContributionsEnabled;
}

export function TimestampContributionsDisabled() {
  const timestampContributionsDisabled: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: "timestampContributionsDisabled",
    args: [],
  }) ?? [];
  return timestampContributionsDisabled;
}


export function ContractNativeCoinBalance() {
  const contractNativeCoinBalance: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: "contractNativeCoinBalance",
    args: [],
  }) ?? [];
  return contractNativeCoinBalance;
}

