

import flavorsBSC from "../assets/images/flavorsBSC.svg"
import gradientTransition from "../assets/images/flavorsGradientTransition.svg"
import nsm from "../assets/images/nsm.png"
import astro from "../assets/images/astro.png"


export const Images = {
  
  gradientTransition: gradientTransition,
  logo: flavorsBSC,
  nsm: nsm,
  astro: astro,
}