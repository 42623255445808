// hooks/index.ts
import { ethers } from "ethers";
import { useContractCall } from "@usedapp/core";
import { Interfaces } from "../components/contracts/Interfaces";
import { Addresses } from "../components/contracts/Addresses";

const contractInterface = Interfaces.PresaleFLV;
const address = Addresses.PresaleFLV;







export function GetHolderInfo(holder:string | null | undefined) {
  const [
    remainingBNBcontribution,
    holdersClaimableFLV,
    holdersCurrentMaxSell,
    holderContributions_,
    claimedFLV_,
    completedContributions_,
    completedClaims_
  ]: any = useContractCall({
    abi: contractInterface,
    address: address,
    method: "getHolderInfo",
    args: [holder],
  }) ?? [];
  return {remainingBNBcontribution,
    holdersClaimableFLV,
    holdersCurrentMaxSell,
    holderContributions_,
    claimedFLV_,
    completedContributions_,
    completedClaims_
  }
}
