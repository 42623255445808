

import { utils } from 'ethers'
import { ABI } from "./ABI"


export const Interfaces = {
  FLV: new utils.Interface(ABI.FLV),
  NSM: new utils.Interface(ABI.NSM),
  WBNB: new utils.Interface(ABI.WBNB),
  WETH: new utils.Interface(ABI.WETH),

  PresaleFLV: new utils.Interface(ABI.PresaleFLV),

  nsm: {
    Pair: new utils.Interface(ABI.Pancake.Pair),
    Migrate: new utils.Interface(ABI.nsm.Migrate),
  },

  NotTools: {
    LPDataV3: new utils.Interface(ABI.NotTools.LPDataV3),
    ERC20Data: new utils.Interface(ABI.NotTools.ERC20Data),
    MultiSend: new utils.Interface(ABI.NotTools.MultiSend),
    BalancesV2: new utils.Interface(ABI.NotTools.BalancesV2),
    LivePriceV3: new utils.Interface(ABI.NotTools.LivePriceV3),
    FactoryCreate2: new utils.Interface(ABI.NotTools.FactoryCreate2),
  },

  Pancake: {
    Router: new utils.Interface(ABI.Pancake.Router),
    Factory: new utils.Interface(ABI.Pancake.Factory),
  },
}
