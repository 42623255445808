import React from 'react';

import {Container, ContentBlock, ContentRow, MainContent, Section, SectionRow} from '../components/base/base';
import {BigLabel} from '../typography/BigLabel';
import {TextInline, TextInlineConsolas} from '../typography/Text';
import {Title} from '../typography/Title';



export function Instructions() {
	return (
				<Section>
					<SectionRow>
						<Title>Presale Instructions</Title>
            
					</SectionRow>
					<ContentBlock>
          <ContentRow>

							<BigLabel>Step 1.</BigLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<BigLabel>Connect Wallet</BigLabel>
						</ContentRow>
						<TextInline>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Click the 'Connect' button to connect your wallet</TextInline>
						<ContentRow>
            <br></br>
            <BigLabel>Step 2.</BigLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<BigLabel>MAX Contributions Hit!</BigLabel>
						</ContentRow>
						<TextInline>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Scroll down to 'Contribute', and deposit up to 8 BNB</TextInline>
            <br></br>
						<ContentRow>
							<BigLabel>Step 3.</BigLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<BigLabel>Claim</BigLabel>
						</ContentRow>
						<TextInline>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;After the Pancake Swap launch, click the 'Claim' button and claim your Flavors Tokens!</TextInline>
            <br></br>
					</ContentBlock>
				</Section>
	);
}
