

import { utils } from 'ethers'
import React from 'react'
import { useContractFunction, useEthers, useTokenBalance, useTokenAllowance, useEtherBalance, useSendTransaction } from '@usedapp/core'

import { TransactionForm } from './TransactionForm'
import { ContractCallSendNoInputs } from './ContractCallSendNoInputs'
import { Addresses } from '../contracts/Addresses'
import { Contracts } from '../contracts/Contracts'




export const TransactionContributeBNB = () => {
  const { account } = useEthers()
  const bnbBalance = useEtherBalance(account)
  const { send, state } = useContractFunction(Contracts.PresaleFLV, 'contribute', { transactionName: 'Contribute Some' })

  const contributeBNB = (bnbBalance: string) => {
    send(utils.parseUnits(bnbBalance, 18))
    // this is the contract's input data
    // send(nsmAmount)
  }

  return (
    <TransactionForm
      balance={bnbBalance}
      send={contributeBNB}
      title="Contribute To Presale"
      ticker="BNB"
      transaction={state}
    />
  )
}




export const TransactionToggleContributions = () => {
  const { state, send } = useContractFunction(Contracts.PresaleFLV, 'toggleContributions_OICM', { transactionName: 'ADMIN: Toggle Contributions' })
  const toggleContributions = () => {
    send()
  }							
  return (
    <ContractCallSendNoInputs
      send={toggleContributions}
      title="ADMIN: Toggle Contributions"
      transaction={state}
    />
  )
}


export const TransactionToggleClaims = () => {
  const { state, send } = useContractFunction(Contracts.PresaleFLV, 'toggleClaims_OICM', { transactionName: 'ADMIN: Toggle Claims' })
  const toggleClaims = () => {
    send()
  }
  return (
    <ContractCallSendNoInputs
      send={toggleClaims}
      title="ADMIN: Toggle Claims"
      transaction={state}
    />
  )
}

export const TransactionAcceptIceCreamManOPI = () => {
  const { state, send } = useContractFunction(Contracts.PresaleFLV, 'acceptIceCreamMan_OPICM', { transactionName: 'PENDING ICM: Accept ICM Role' })
  const AcceptIceCreamMan = () => {
    send()
  }
  return (
    <ContractCallSendNoInputs
      send={AcceptIceCreamMan}
      title="PENDING ICM: Accept ICM Role"
      transaction={state}
    />
  )
}

export const TransactionTransferContributedBNB = () => {
  const { state, send } = useContractFunction(Contracts.PresaleFLV, 'transferContributedBNB_OAUTH', { transactionName: 'ADMIN: Transfer Contributed BNB' })
  const transferContributedBNB_OAUTH = () => {
    send()
  }
  return (
    <ContractCallSendNoInputs
      send={transferContributedBNB_OAUTH}
      title="ADMIN: Transfer Contributed BNB"
      transaction={state}
    />
  )
}
/////////////////////////////////////////////////////////////////////

export const TransactionClaimFLV = () => {
  const { state, send } = useContractFunction(Contracts.PresaleFLV, 'claim_OWL', { transactionName: 'Claim FLV' })
  const claimFLV = () => {
    send()
  }
  return (
    <ContractCallSendNoInputs
      send={claimFLV}
      title="Claim Flavors Token"
      transaction={state}
    />
  )
}


