import React from 'react';
import {formatEther} from '@ethersproject/units';

import {useEtherBalance, useEthers, useBlockNumber, useBlockMeta, useTokenBalance} from '@usedapp/core';
import {Container, ContentBlock, ContentRow, MainContent, Section, SectionRow} from '../components/base/base';
import {Label} from '../typography/Label';
import {Title} from '../typography/Title';
import {formatUnits} from '@ethersproject/units';
import { BigLabel } from '../typography/BigLabel';

import { Addresses } from '../components/contracts/Addresses';
import { TextInlineConsolas } from '../typography/Text';
import { GetInfo } from '../hooks/GetInfo';
import { GetAddresses } from '../hooks/GetInfo';
//import { IceCreamMan } from '../components/getData/IceCreamMan';
//import { GetIceCreamMan, GetInfo } from '../hooks/GetInfo';
//import { GetMyInfo } from '../hooks/GetMyInfo';
import { helpers } from '../components/helpers/helpers';
//import { GetPresaleContractAddress } from '../hooks/GetInfo';
//import { GetCreamery } from '../hooks/GetInfo';
import { GetHolderInfo } from '../hooks/GetMyInfo';
export function Stats2() {
	const blockNumber = useBlockNumber();
	const {chainId} = useEthers();
	const {timestamp} = useBlockMeta();

	const {account} = useEthers();
	const userBalance = useEtherBalance(account);
	const userBalanceNSM = useTokenBalance(Addresses.NSM, account);
  //const userBalanceFLV = useTokenBalance(Addresses.FLV, account);
  //const creamery = GetCreamery();
	//const iceCreamMan = GetIceCreamMan();
  //const presaleContractAddress = GetPresaleContractAddress();
	//const {remainingBNBcontribution_,maxClaimableFLV,holdersTotalClaimableFLV,remainingClaimableFLV_,holdersCurrentMaxSell_} = GetMyInfo();
  
  const {remainingBNBcontribution,  holdersClaimableFLV,  holdersCurrentMaxSell,  holderContributions_,  claimedFLV_,  completedContributions_,  completedClaims_} = GetHolderInfo(account);
  
  const {globalTotal_maxContribution_, globalTotal_contributions_, globalTotal_claims_, flvPerNativeCoin_, claimsEnabled_, contributionsEnabled_} = GetInfo();
  
  const {presaleFLV, flv, creamery, iceCreamMan_, pendingICM_} = GetAddresses();
	//Addresses.nsm.Migrate
	return (
		<MainContent>
			<Container>
				<Section>
					<SectionRow>            
						<ContentBlock>
              <ContentRow>
                <BigLabel>Info</BigLabel>
              </ContentRow>
              
							{presaleFLV && (
								<ContentRow>
									<Label>Presale Contract Address: </Label> <TextInlineConsolas>{presaleFLV}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
							{iceCreamMan_ && (
								<ContentRow>
									<Label>Ice Cream Man: </Label> <TextInlineConsolas>{iceCreamMan_}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
							{creamery && (
								<ContentRow>
									<Label>Creamery: </Label> <TextInlineConsolas>{creamery}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
              
              
							{remainingBNBcontribution && (
								<ContentRow>
									<Label>remainingBNBcontribution: </Label> <TextInlineConsolas>{Number(remainingBNBcontribution._hex)/(10**9)}</TextInlineConsolas>{' '}
								</ContentRow>
							)}		
              
              
							{holderContributions_ && (
								<ContentRow>
									<Label>holderContributions_: </Label> <TextInlineConsolas>{Number(holderContributions_._hex)/(10**9)}</TextInlineConsolas>{' '}
								</ContentRow>
							)}		
              
							{holdersClaimableFLV && (
								<ContentRow>
									<Label>holdersClaimableFLV: </Label> <TextInlineConsolas>{Number(holdersClaimableFLV._hex)/(10**9)}</TextInlineConsolas>{' '}
								</ContentRow>
							)}							
              {claimedFLV_ && (
								<ContentRow>
									<Label>Claimed FLV: </Label> <TextInlineConsolas>{Number(claimedFLV_._hex)/(10**9)}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
							{holdersCurrentMaxSell && (
								<ContentRow>
									<Label>Holder Current Max Sell: </Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(holdersCurrentMaxSell._hex)/(10**9))}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
              
						</ContentBlock>
					</SectionRow>
				</Section>
			</Container>
		</MainContent>
	);
}
	
  
	{/*
	return (
		<MainContent>
			<Container>
				<Section>
					<SectionRow>
						<Title>My Info</Title>
					</SectionRow>
					<SectionRow>
						<ContentBlock>
              {/*
							{snapshotBalance_ && (
								<ContentRow>
									<Label>Balance: </Label> <TextInlineConsolas>{snapshotBalance_}</TextInlineConsolas>{' '}
								</ContentRow>
                )}
							{snapshotAmountOutBNB_ && (
								<ContentRow>
									<Label>AmountOutBNB: </Label> <TextInlineConsolas>{snapshotAmountOutBNB_}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
							{holderDeposits_ && (
								<ContentRow>
									<Label>Date: </Label> <TextInlineConsolas>{holderDeposits_}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
			        {remainingNSMdeposit_ && (
								<ContentRow>
									<Label>Reserves NSM: </Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(remainingNSMdeposit_._hex/(10**9)))}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
							{maxClaimableFLV && (
								<ContentRow>
									<Label>Reserves BNB: </Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(maxClaimableFLV._hex/(10**18)))}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
							{currentClaimableFLV && (
								<ContentRow>
									<Label>Holder Balance Total NSM: </Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(currentClaimableFLV._hex/(10**9)))}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
							{claimedFLV_ && (
								<ContentRow>
									<Label>Holder AmountOutBNB Total: </Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(claimedFLV_._hex/(10**9)))}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
							{remainingClaimableFLV_ && (
								<ContentRow>
									<Label>Total Deposits: </Label> <TextInlineConsolas>{Number(remainingClaimableFLV_._hex/(10**9))}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
							{completedDeposits_ && (
								<ContentRow>
									<Label>Global Total Claims: </Label> <TextInlineConsolas>{Number(completedDeposits_._hex/(10**9))}</TextInlineConsolas>{' '}
								</ContentRow>
							)}
							{completedClaims_ && (
								<ContentRow>
									<Label>FLV Per Native Coin: </Label> <TextInlineConsolas>{Number(completedClaims_._hex/(10**9))}</TextInlineConsolas>{' '}
								</ContentRow>
              )}
              
						</ContentBlock>
					</SectionRow>
				</Section>
			</Container>
		</MainContent>
	);
}
*/}