import React from 'react';
import {formatEther} from '@ethersproject/units';

import {useEtherBalance, useEthers, useBlockNumber, useBlockMeta, useTokenBalance} from '@usedapp/core';
import {Container, ContentBlock, ContentRow, MainContent, Section, SectionRow} from '../components/base/base';
import {Label} from '../typography/Label';
import {Title} from '../typography/Title';
import {formatUnits} from '@ethersproject/units';
import { BigLabel } from '../typography/BigLabel';

import { Addresses } from '../components/contracts/Addresses';
import { TextInlineConsolas } from '../typography/Text';

//import { IceCreamMan } from '../components/getData/IceCreamMan';
import { GetInfo, GetAddresses } from '../hooks/GetInfo';
//import { GetMyInfo } from '../hooks/GetMyInfo';
import { helpers } from '../components/helpers/helpers';
import { Stats2 } from './Stats2';
//import { PresaleContractAddress } from '../components/getData/PresaleContractAddress';



export function Stats() {
	const blockNumber = useBlockNumber();
	const {chainId} = useEthers();
	const {timestamp} = useBlockMeta();

	const {account} = useEthers();
	const userBalance = useEtherBalance(account);
  const userBalanceFLV = useTokenBalance(Addresses.FLV, account);
	//const iceCreamMan = GetIceCreamMan();
  //const presaleContractAddress = PresaleContractAddress();
	const {globalTotal_maxContribution_, globalTotal_contributions_, globalTotal_claims_, flvPerNativeCoin_, claimsEnabled_, contributionsEnabled_} = GetInfo();
  const {presaleFLV, flv, creamery, iceCreamMan_, pendingICM_} = GetAddresses();
  //const {remainingBNBcontribution,  holdersClaimableFLV,  holdersCurrentMaxSell,  holderContributions_,  claimedFLV_,  completedContributions_,  completedClaims_} = GetMyInfo();
  
    return (
		<MainContent>
      
			<Container>
				<Section>
					<SectionRow>
						<ContentBlock>
              <ContentRow>
                <BigLabel>Total Contributions</BigLabel>
              </ContentRow>
							{globalTotal_maxContribution_ && (
								<ContentRow>
									<Label> Max Contribution per Wallet: </Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(globalTotal_maxContribution_._hex/(10**18)))} BNB</TextInlineConsolas>{' '}
								</ContentRow>
							)}
							{globalTotal_contributions_ && (
								<ContentRow>
									<Label>Total Contributions: </Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(globalTotal_contributions_._hex/(10**18)))} BNB</TextInlineConsolas>{' '}
								</ContentRow>
							)}
							{globalTotal_claims_ && (
								<ContentRow>
									<Label>Presale Tokens Claimed: </Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(globalTotal_claims_._hex/(10**9)))} FLV</TextInlineConsolas>{' '}
								</ContentRow>
							)}
							{flvPerNativeCoin_ && (
								<ContentRow>
									<Label>Rate: </Label><TextInlineConsolas>{helpers.numberWithCommas(Number(flvPerNativeCoin_._hex/(10**9)))}FLV per BNB</TextInlineConsolas>{' '}
								</ContentRow>
              )}
						</ContentBlock>
					</SectionRow>



				</Section>
			</Container>
		</MainContent>
	);
}