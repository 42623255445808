import React from 'react'

import { Container, MainContent, Section, SectionRow } from '../components/base/base'
import { Title } from '../typography/Title'
import styled from 'styled-components'

import { TransactionToggleContributions, TransactionTransferContributedBNB, TransactionToggleClaims, TransactionAcceptIceCreamManOPI } from '../components/Transactions/PresaleTx'


export function Admin() {

  return (
    <MainContent>
      <Container>
        <Section>
          <SectionRow>
            <Title>Admin</Title>
          </SectionRow>

          <TableGrid>
            <TransactionToggleContributions />
            <TransactionToggleClaims />
            <TransactionTransferContributedBNB />
            <TransactionAcceptIceCreamManOPI />
          </TableGrid>
        </Section>
      </Container>
    </MainContent>
  )
}

const TableGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
`
