import React from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {Colors, Sizes, Fonts, Transitions} from '../global/styles';
import {ContentBlock, HeaderContainer} from './base/base';
import {AccountButton} from '../components/account/AccountButton';
import {Images} from './Images';
import {SectionRow, Section} from './base/base';
import {ContentRow} from './base/base';
//import { IceCreamMan } from './getData/IceCreamMan';
//import { GetIceCreamMan } from '../hooks/GetInfo';
//import { GetMyInfo } from '../hooks/GetMyInfo';
import { useEthers } from '@usedapp/core';
import { GetAddresses } from '../hooks/GetInfo';
import { Label } from '../typography/Label';
import { TextInlineConsolas } from '../typography/Text';


export function TopBar() {
  const {account} = useEthers();
  const {presaleFLV, flv, creamery, iceCreamMan_, pendingICM_} = GetAddresses();


	return (
		<Header>
			<HeaderContainer>
        <SectionRow>
					<ContentRow>
							
            <ToMain href="/presale">
							<ToMainBottom>
            <img src={Images.logo} width="150px" />
								<span>
                
									{/*<Handshaking>🍨🍦🥧🧁🍰🎂🍮🍭🍬🍫🍿🍩🍪</Handshaking>*/}
								</span>
							</ToMainBottom>
						</ToMain>
					</ContentRow>
          
          </SectionRow>
					{/*	<HeaderNav>
						<ContentRow>
							<HeaderNavLinks>
							<HeaderLink activeClassName="active-page" to="/Info">
									{' '}
									Info{' '}
  </HeaderLink>*/}
								{/*<HeaderLink activeClassName="active-page" to="/Contribute">
									{' '}
									Contribute{' '}
                </HeaderLink>
								<HeaderLink activeClassName="active-page" to="/Claim">
									{' '}
									Claim{' '}
								</HeaderLink>
								<HeaderLink activeClassName="active-page" to="/stats">
									{' '}
									Stats{' '}
								</HeaderLink>
								<HeaderLink activeClassName="active-page" to="/stats2">
									{' '}
									Stats2{' '}
								</HeaderLink>*/}
              

								{/*<HeaderLink activeClassName="active-page" to="/admin">
									{' '}
									Admin{' '}
								</HeaderLink>
							</HeaderNavLinks>
						</ContentRow>
					</HeaderNav>*/}
			<AccountButton />
			</HeaderContainer>
		</Header>
	);
}

const Space = styled.header`
	display: flex;
	position: relative;
	height: 100%;
	padding: 40px;
	white-space: nowrap;
`;

const Header = styled.header`
	display: flex;
	position: fixed;
	top: 20px;
  left: 20px;
  right: 20px:
	align-items: center;
	width: 100%;
	height: ${Sizes.headerHeight};
	z-index: 100;
`;

const HeaderNav = styled.nav`
	display: flex;
	align-items: center;
	height: 100%;
	position: relative;
`;

const ToMain = styled.a`
	display: flex;
	flex-direction: column;
	width: fit-content;
	font-size: 18px;
	line-height: 24px;
	font-weight: 700;
	transition: ${Transitions.all};

	&:hover {
		color: ${Colors.Yellow[500]};
	}
`;

const ToMainBottom = styled.span`
	display: grid;
	grid-auto-flow: column;
	grid-column-gap: 4px;
	align-items: center;
	width: fit-content;
	font-size: 10px;
	line-height: 14px;
	font-weight: 500;
`;

const Handshaking = styled.span`
	letter-spacing: -0.3em;
`;

const HeaderNavLinks = styled.div`
	display: grid;
	position: absolute;
	left: 60%;
	grid-auto-flow: column;
	align-items: center;
	grid-column-gap: 10px;
	align-items: center;
	height: 100%;
	transform: translateX(-50%);
`;

const HeaderLink = styled(NavLink)`
	font-family: ${Fonts.Montserrat};
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: 10px;
	font-size: 12px;
	line-height: 24px;
	font-weight: 700;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	transition: ${Transitions.all};
	white-space: nowrap;

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		width: calc(100% - 20px);
		height: 2px;
		background-color: ${Colors.flavors.main.bysantine};
		transform: scaleX(0);
		transform-origin: 50% 50%;
		transition: ${Transitions.all};
	}

	&:hover {
		color: ${Colors.flavors.main.bysantine};

		&:after {
			transform: scaleX(1);
			color: ${Colors.flavors.main.bysantine};
		}
	}
	&.active-page {
		color: ${Colors.flavors.main.bysantine};
		&:after {
			transform: scaleX(1);
		}
	}
`;
//color: ${Colors.Yellow[500]};
