
import './App.css';

import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import { Page } from './components/base/base';
import { GlobalStyle } from './global/GlobalStyle';
import { BrowserRouter } from 'react-router-dom'
import { NotificationsList } from './components/Transactions/History';
import { Presale } from './pages/Presale';
import { Stats } from './pages/Stats';
import { Stats2 } from './pages/Stats2';
import { Contribute } from './pages/Contribute';
import { Claim } from './pages/Claim';
import { Admin } from './pages/Admin';

import { TopBar } from './components/TopBar'

function App() {
  return (
    <Page>
    <GlobalStyle />
    <BrowserRouter>
      <TopBar />
      <Switch>
        <Route exact path="/presale" component={Presale} />
        <Route exact path="/stats" component={Stats} />
        <Route exact path="/stats2" component={Stats2} />
        <Route exact path="/contribute" component={Contribute} />
        <Route exact path="/claim" component={Claim} />
        <Route exact path="/admin" component={Admin} />
        <Redirect exact from="/" to="/presale" />
      </Switch>
    </BrowserRouter>
    <NotificationsList />
  </Page>

  )
}

export default App;
