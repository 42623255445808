import React from 'react';
import {formatEther} from '@ethersproject/units';

import {useEtherBalance, useEthers, useBlockNumber, useBlockMeta, useTokenBalance} from '@usedapp/core';
import {Container, ContentBlock, ContentRow, MainContent, Section, SectionRow} from '../components/base/base';
import {Label} from '../typography/Label';
import {Title} from '../typography/Title';
import {formatUnits} from '@ethersproject/units';
import { BigLabel } from '../typography/BigLabel';

import { Addresses } from '../components/contracts/Addresses';
import { TextInlineConsolas } from '../typography/Text';

//import { IceCreamMan } from '../components/getData/IceCreamMan';
import { GetInfo, GetAddresses } from '../hooks/GetInfo';
//import { GetMyInfo } from '../hooks/GetMyInfo';
import { helpers } from '../components/helpers/helpers';
import { Stats2 } from './Stats2';
//import { PresaleContractAddress } from '../components/getData/PresaleContractAddress';
import { GetHolderInfo } from '../hooks/GetMyInfo';


export function MyContributions() {
	const blockNumber = useBlockNumber();
	const {chainId} = useEthers();
	const {timestamp} = useBlockMeta();

	const {account} = useEthers();
	const userBalance = useEtherBalance(account);
  const userBalanceFLV = useTokenBalance(Addresses.FLV, account);
  const {remainingBNBcontribution,  holdersClaimableFLV,  holdersCurrentMaxSell,  holderContributions_,  claimedFLV_,  completedContributions_,  completedClaims_} = GetHolderInfo(account);
  
    return (      
				<Section>
						<ContentBlock>
								<ContentRow>
              <BigLabel>My Contributions</BigLabel>
              </ContentRow>
							{holderContributions_ && (
								<ContentRow>
									<Label>My Contribution </Label><TextInlineConsolas>{helpers.numberWithCommas(Number(holderContributions_._hex)/(10**18))} BNB</TextInlineConsolas>{' '}
								</ContentRow>
              )}
              {remainingBNBcontribution && (
								<ContentRow>
									<Label>My Remaining BNB Contribution: </Label> <TextInlineConsolas>{Number(remainingBNBcontribution._hex/(10**18))} BNB</TextInlineConsolas>{' '}
								</ContentRow>
							)}
						

							{holdersClaimableFLV && (
								<ContentRow>
									<Label>My Claimable FLV: </Label> <TextInlineConsolas>{helpers.numberWithCommas(Number(holdersClaimableFLV._hex/(10**9)))} FLV</TextInlineConsolas>{' '}
								</ContentRow>
							)}
              {/*
							{holdersCurrentMaxSell && (
								<ContentRow>
									<Label>My Maximum Sellable Allowance: </Label><TextInlineConsolas>{helpers.numberWithCommas(Number(holdersCurrentMaxSell._hex/(10**9)))}</TextInlineConsolas>{' '}
								</ContentRow>
              )}*/}
							{claimedFLV_ && (
								<ContentRow>
									<Label>My Claimed FLV </Label><TextInlineConsolas>{helpers.numberWithCommas(Number(claimedFLV_._hex/(10**9)))}</TextInlineConsolas>{' '}
								</ContentRow>
              )}

						</ContentBlock>
				</Section>
	);
}