import { useEthers } from "@usedapp/core";




export const Addresses = {
  FLV: '0x0000000000000000000000000000000000000000',    // MAINNET BSC
  //FLV: '0xEF7D4fb2E617404Df361a30794e2daAE1d47Bb8b',// TESTNET BSC
  NSM: '0x337e35Ed5B38D5C7Ec9F8d7cF78fe7F43d7DEC6F',   // MAINNET BSC
  //NSM: '0x6746767c6dF2A2bF497b4DfBbB1b0767b9946786', // TESTNET BSC
  WBNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',   // MAINNET BSC
  //WBNB: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd', // TESTNET BSC
  WETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',   // MAINNET BSC

  PresaleFLV: '0x78fDf2F1985118943FF8fc7d4d9702D9820d7C9D', //  MAINNET BSC
  nsm: {
  //  Pair: '0x357f9cd8f2749A31119C3E32729965CA56f4cBd8', // TESTNET BSC
    Pair: '0x73BEDe1D162c3DCD38b5a44c5637ae66D5f11785',   // MAINNET BSC
    Migrate: '0xC63a76B9Dc7dbad4a786969a13613a35466cA9a0',
  },
  
  NotTools: {
    LPDataV3: '0xfffff8fE58810B693DA405bEfab496E64EC1E40E',
    ERC20Data: '0xD00DAD8e9680d863bf178fC74F6F58deA1bcc34b',
    MultiSend: '0x7117113772b3e95BeFAC2d5a183e60070465CE95',
    BalancesV2: '0xaaaa1D1Ba4572B419584e2C30a357A890172B32C',
    LivePriceV3: '0x4444434a5917ae1c00FE51705a8C9C4B36887cde',
    FactoryCreate2: '0x04396f9531c5f3cd0fac735f661a987e7e08fc87',
  },

  Pancake: {
    Factory: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
    //Router: '0x10ed43c718714eb63d5aa57b78b54704e256024e',// MAINNET BSC
    Router: '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3',// TESTNET BSC
  }
}